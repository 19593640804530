// A2HS = Add To Home Screen

import { useLocalstorage } from '../localStorage/store'

const { persist } = useLocalstorage()

const state = reactive({
  hideA2HS: persist('hideA2HS', false)
})

const isIos = () => /iPad|iPhone|iPod/.test(window.navigator.platform) || (window.navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone'])

const isVisibleA2HS = computed<boolean>(() => !state.hideA2HS && isIos() && !isInStandaloneMode())

const hideA2HS = (): void => {
  state.hideA2HS = true
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useA2HSStore = () => ({
  hideA2HS,
  isVisibleA2HS
})
