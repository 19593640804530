const SHOW_ERROR_TIMEOUT_MS = 1500
const state = reactive({
    startedRequests: 0,
    finishedRequests: 0,
    totalRequests: 0,
    hasError: false,
    batchFinished: false,
})
const hasError = computed(() => state.hasError)

const start = () => {
    state.startedRequests++
    state.totalRequests++
}

const finish = () => {
    state.finishedRequests++
    if (state.finishedRequests >= state.totalRequests) {
        state.batchFinished = true
        setTimeout(reset, 500)
    }
}

function reset() {
    state.startedRequests = 0
    state.finishedRequests = 0
    state.totalRequests = 0
    state.hasError = false
    state.batchFinished = false
}

const error = () => {
    state.hasError = true
    setTimeout(reset, SHOW_ERROR_TIMEOUT_MS)
}

const progress = computed(() => {
    if (state.batchFinished) {
        return 100
    }
    return ((state.finishedRequests / state.totalRequests) || 0) * 100
})

const isLoading = computed(() => {
    return state.startedRequests > 0
})

export const useLoadingIndicator = () => {
    return {
        start,
        finish,
        error,
        progress,
        isLoading,
        hasError,
    }
}
