<template>
  <header id="app-header" class="flex flex-col">
    <!-- Banner for info notices, maintenance warnings etc -->
    <TopInfoBanner v-if="topInfoBannerEnabled" :color="topInfoBannerColor || 'yellow'" :text="topInfoBannerText" />
    <div class="relative z-20 flex flex-shrink-0 items-center justify-between bg-profileDarkGreen dark:bg-green-800 py-1 px-2 sm:py-2 shadow-md">
      <div class="flex flex-shrink-0">
        <nuxt-link
          to="/"
          @click.native="onNavigateHome"
          class="block pl-4 pr-2"
        >
          <GMLogo
            v-if="logoName"
            :name="logoName"
            class="hidden lg:block h-7 text-profileLightGreen"
          />

          <GMLogo
            v-if="logoNameSimple"
            :name="logoNameSimple"
            class="lg:hidden h-7 text-profileLightGreen"
          />
        </nuxt-link>
        <div
          v-if="headerLink"
          class="top-4 lg:left-12 flex items-center md:w-44"
        >
          <nuxt-link
            :to="headerLink.Url"
            class="
              flex
              items-center
              px-2
              rounded-sm
              focus:bg-green-700
              ring-green-600
              focus:ring-2
              focus:outline-none
              cursor-pointer
              uppercase
              text-xs
              text-green-300
            "
          >
            <GMIcon name="chevron-left" class="w-6 h-6" />
            <span class="sr-only sm:not-sr-only">{{ $t(headerLink.Label) }}</span>
          </nuxt-link>
        </div>
      </div>

      <div class="flex-1 px-2 sm:px-4">
        <Search :info-banner-is-enabled="topInfoBannerEnabled" />
      </div>

      <div
        v-if="!env.toString().toUpperCase().includes('PROD')"
        class="absolute top-0 right-0 block my-0 px-2 max-w-xs bg-red-600 text-white text-xxs leading-3 tracking-wider shadow-md rounded-bl-sm"
      >
        {{ env }}
      </div>

      <div class="flex flex-row justify-end">
        <button
          v-if="notesFeatureEnabled"
          class="
            flex
            items-center
            float-right
            p-2
            rounded-sm
            focus:bg-green-700
            ring-green-600
            focus:ring-2
            text-green-300
            focus:outline-none
            cursor-pointer
            uppercase
            text-xs
          "
          @click="toggleNotes()"
        >
          <div class="flex flex-col sm:flex-row items-center">
            <span class="sm:mr-0.5 text-xxs">
              Notater
            </span>
            <GMIcon
              name="document-text"
              class="order-first w-6 h-6 text-green-300"
            />
          </div>
        </button>

        <button
          class="
            flex
            items-center
            float-right
            p-2
            rounded-sm
            focus:bg-green-700
            ring-green-600
            focus:ring-2
            text-green-300
            focus:outline-none
            cursor-pointer
            uppercase
            text-xs
          "
          @click="toggleSidebar()"
        >
          <div class="flex flex-col sm:flex-row items-center">
            <span class="sm:mr-0.5 text-xxs">
              {{ $t("MENU") }}
            </span>
            <GMIcon
              :name="isSidebarOpen ? 'x' : 'menu'"
              class="order-first w-6 h-6 text-green-300"
            />
          </div>
          <span v-if="hamburgerBadgeNumber > 0" class="badge bg-red-800">
            {{ hamburgerBadgeNumber }}
          </span>
        </button>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import {GMIcon, GMLogo} from '@gm/components'
import Search from '@/components/ui/Search.vue'
import {useSidebar} from '@/use/ui/sidebar'
import {useUserStore} from '@/use/user/store'
import {useHeaderStore} from '@/use/ui/header'
import {useCustomersStore} from '@/use/customers/store'
import {useSearchPanel} from '@/use/ui/searchPanel'
import {useQueueSizeStore} from '@/use/ui/queueSize'
import {useNotes} from '~/use/notes/notes'
import {useProjectOrdersStore} from '~/use/projectOrders/store'
import {useProjectStore} from '~/use/project/store'
import {featureEnabled} from '~/helpers/features';
import {useTransportStore} from '~/use/transport/store';
import {useDataStore} from "~/use/data/store";

export default defineComponent({
  name: 'AppHeader',
  components: {
    GMLogo,
    Search,
    GMIcon
  },
  setup () {
    const runtimeConfig = useRuntimeConfig()
    const env = computed(() => runtimeConfig.public.environment)
    const { toggleSidebar, isSidebarOpen } = useSidebar()
    const { headerLink } = useHeaderStore()
    const { searchTerm } = useCustomersStore()
    const { isAsUser } = useUserStore()
    const { isSearchPanelOpen, toggleSearchPanel } = useSearchPanel()
    const { queueSize } = useQueueSizeStore()
    const { toggleNotes, closeNotes } = useNotes()
    const notesFeatureEnabled = featureEnabled(runtimeConfig.public.FEATURE_SBL_NOTES)
    const topInfoBannerEnabled = featureEnabled(runtimeConfig.public.FEATURE_ENABLE_TOP_INFO_BANNER)
    const topInfoBannerColor = computed(() => {
      const color = runtimeConfig.public.TOP_INFO_BANNER_COLOR as string
      if (color?.length && ['yellow', 'green', 'red', 'blue'].includes(color.toLowerCase())) {
        return color.toLowerCase()
      }
    })
    const topInfoBannerText = runtimeConfig.public.TOP_INFO_BANNER_TEXT

    const { nonInvoicedOrders } = useProjectOrdersStore()
    const { projectsReady2Close } = useProjectStore()
    const { parkedTransportOrders } = useTransportStore()

    const hamburgerBadgeNumber = computed<number>(() => {
      return queueSize.value + nonInvoicedOrders.value.length + projectsReady2Close.value.length + parkedTransportOrders.value.length
    })

    const logoName = computed<string>(() => {
      const type = headerLink.value ? 'simple' : 'horizontal'
      const prefix = isAsUser.value ? 'as-' : ''
      return prefix + type
    })

    const logoNameSimple = computed<string>(() => isAsUser.value ? 'as-simple' : 'simple')

    const onNavigateHome = () => {
      closeNotes()
    }


    return {
      env,
      headerLink,
      isAsUser,
      isSidebarOpen,
      isSearchPanelOpen,
      logoName,
      logoNameSimple,
      searchTerm,
      toggleSearchPanel,
      toggleSidebar,
      hamburgerBadgeNumber,
      toggleNotes,
      notesFeatureEnabled,
      onNavigateHome,
      topInfoBannerEnabled,
      topInfoBannerColor,
      topInfoBannerText,
    }
  }
})
</script>

<style scoped>
.badge {
  @apply absolute text-xs inline-block rounded-full text-center;
  width: 1.3em; /* em unit */
  height: 1.3em;
  vertical-align: middle;
  padding: 0.1em;
  line-height: 1.3em;
  top: 5px;
  right: 5px;
}
.search-panel {
  height: calc(100vh - 54px);
  top: 54px;
}
</style>
