<template>
  <main class="w-full p-4">
    <NotesList show-searchbar />
  </main>
</template>

<script lang="ts">
import NotesList from '~/components/Notes/NotesList.vue'

export default defineComponent({
  components: {
    NotesList
  }
})
</script>
