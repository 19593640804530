<script lang="ts" setup>
import {useLoadingIndicator} from '~/use/loadingIndicator';
import {useAxiosClient} from '~/use/axios/client';

const { start, finish, error, hasError, progress, isLoading } = useLoadingIndicator()
const { axiosClient } = useAxiosClient()

axiosClient.value.interceptors.request.use(async (config) => {
  start()
  return config
}, async (err) => {
  start()
  return Promise.reject(err)
})

axiosClient.value.interceptors.response.use((response) => {
  finish()
  return response
}, (err) => {
  error()
  return Promise.reject(err)
})

</script>

<template>
  <div class="axios-network-progress"
       :class="{
          'bg-red-500': hasError,
          'bg-green-500': !hasError,
       }"
       :style="{
         width: hasError ? '100%' : progress + '%',
         left: 0,
         opacity: isLoading ? 1 : 0
       }"
  ></div>
</template>

<style>
.axios-network-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  width: 0;
  opacity: 1;
  transition: width .1s,opacity .4s;
  z-index: 999999;
}
</style>
